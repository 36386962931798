/* Header Styles */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #444847; /* Adjust this color as needed */
  padding: 10px;
  color: #f8f9fa;
}

.header-title {
  font-size: 2rem;
  font-weight: bold;
  color: #f8f9fa;
  margin: 0;
  text-align: center;
  flex: 1; /* This ensures that the title takes up the full width of the header */
}

.header-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-link {
  margin: 0 0.5rem;
  text-decoration: none;
  color: #f8f9fa;
  font-size: 1rem;
  transition: color 0.3s, transform 0.3s;
}

.nav-link:hover {
  color: #007bff;
  transform: scale(1.1);
}

.nav-link-active {
  color: #007bff;
  font-weight: bold;
}





/* Main Styles */
main {
  flex: 1;
  padding: 1rem;
}

/* Container Styles */
.container {
  padding: 1rem;
  max-width: 100%;
}

/* Footer Styles */
.footer {
  text-align: center;
  padding: 1rem;
  background-color: #343a40;
  color: #f9f9f9;
  border-top: 1px solid #dee2e6;
}

.footer-text {
  margin: 0;
}



/* FullCalendar mobile improvements */

/* For small screens */
@media (max-width: 600px) {
  .calendar-container {
    padding: 0;
    margin: 0;
  }

  .fc-toolbar {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .fc-toolbar h2 {
    font-size: 18px;
  }

  .fc-toolbar button {
    padding: 5px;
    font-size: 12px;
  }

  .fc-daygrid-event {
    font-size: 12px;
    padding: 5px;
  }

  /* Ensure the calendar is scrollable horizontally */
  .fc {
    width: 100%;
    overflow-x: scroll;
  }

  .fc-daygrid-day {
    height: auto;
  }
}

/* Larger clickable area for touch devices */
.fc-daygrid-day {
  padding: 10px;
  min-height: 60px;
}

/* Simplify event display */
.fc-event-title {
  font-size: 12px;
}

.fc-event-time {
  font-size: 10px;
}


