/* Analytics Container */
.analytics-container {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
  }
  
  @media (max-width: 768px) {
    .analytics-container {
      padding: 10px;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    h2 {
      font-size: 1.2rem;
    }
  }
  