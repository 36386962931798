/* index.css */

/* CSS Variables */
:root {
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --font-code: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  --color-primary: #007bff;
  --color-secondary: #343a40;
  --color-light: #f8f9fa;
  --color-dark: #343a40;
  --color-danger: #d9534f;
}






/* Table Styles */
.records-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.records-table th,
.records-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.records-table th {
  background-color: var(--color-secondary);
  color: var(--color-light);
}

.records-table tbody tr:nth-child(even) {
  background-color: var(--color-light);
}






