/* General Styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

/* Filter Container */
.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.filter-container input[type="date"],
.filter-container input[type="text"] {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px 0;
}

/* Table Styles */
.table-container {
  overflow-x: auto;
}

.records-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.records-table th,
.records-table td {
  padding: 15px;
  text-align: left;
  border: 1px solid #ddd;
}

.records-table th {
  background-color: #f4f4f4;
  cursor: pointer;
}

.records-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.records-table tbody tr:hover {
  background-color: #d83d3d;
}

/* Status Badges */
.status-picked-up {
  background-color: #4caf50;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
}

.status-pending {
  background-color: #ff9800;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
}

/* Buttons */
.btn {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin: 5px;
}

.btn-edit {
  background-color: #007bff;
  color: white;
}

.btn-edit:hover {
  background-color: #0056b3;
}

.btn-delete {
  background-color: #d9534f;
  color: white;
}

.btn-delete:hover {
  background-color: #c9302c;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-primary:hover {
  background-color: #0056b3;
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  z-index: 1000;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Form Styles */
form {
  display: flex;
  flex-direction: column;
}

form div {
  margin-bottom: 15px;
}

form label {
  margin-bottom: 5px;
  font-weight: bold;
}

form input,
form select {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

form .btn-update,
form .btn-add-log {
  background-color: #28a745;
  color: white;
}

form .btn-update:hover,
form .btn-add-log:hover {
  background-color: #218838;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.pagination button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button.active {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .filter-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .records-table th,
  .records-table td {
    padding: 10px;
    font-size: 0.9rem;
  }

  .btn {
    padding: 8px 12px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .records-table th,
  .records-table td {
    font-size: 0.8rem;
    padding: 8px;
  }

  .btn {
    padding: 6px 10px;
    font-size: 0.8rem;
  }
}


.delete-btn {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 1rem;
  margin-left: 10px;
}

.delete-btn i {
  pointer-events: none; /* Ensures the icon itself is not clickable, only the button */
}

.delete-btn:hover {
  color: darkred;
}


