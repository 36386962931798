.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  animation: fadeIn 1s ease-in-out;
}

h2 {
  color: #444847;
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  animation: fadeIn 1.5s ease-in-out;
}

form {
  display: flex;
  flex-direction: column;
}

form div {
  margin-bottom: 15px;
  animation: fadeIn 2s ease-in-out;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

input[type="text"],
input[type="datetime-local"],
.react-autosuggest__input {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.radio-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.radio-group label {
  margin-right: 10px;
}

.radio-group input[type="radio"] {
  margin-right: 5px;
}

button,
.forgot-log-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #444847;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  animation: fadeIn 2.5s ease-in-out;
  width: 100%;
  box-sizing: border-box;
}

.forgot-log-container,
.reminder {
  margin-top: 20px;
  text-align: center;
}

.reminder p {
  color: #333;
  font-size: 1rem;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.error {
  color: red;
  font-size: 0.9rem;
  margin-top: 10px;
}

.daily-updates {
  margin-top: 30px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.daily-updates h3 {
  color: #444847;
  margin-bottom: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
