/* Ensure the calendar container takes full width on small screens */
.calendar-container {
    margin: 0 auto;
    max-width: 100%;
    padding: 10px;
  }
  
  /* Default toolbar button styles */
  .fc-toolbar button {
    font-size: 0.85rem; /* Default font size */
    padding: 6px 12px; /* Default padding */
    margin: 4px; /* Add margin to space buttons */
    border-radius: 4px; /* Add slight border radius for better appearance */
    background-color: #007bff; /* Button background color */
    color: white;
    border: none; /* Remove border */
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .fc-toolbar button:hover {
    background-color: #0056b3; /* Button hover state */
  }
  
  /* Align toolbar buttons and title properly for desktop */
  .fc-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .fc-toolbar-title {
    font-size: 1.5rem; /* Default font size for calendar title */
    margin: 0 15px;
  }
  
  /* Ensure buttons and title are responsive for mobile screens */
  @media (max-width: 600px) {
    .fc-toolbar {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;
    }
  
    .fc-toolbar-title {
      font-size: 1.2rem; /* Smaller font size for title */
      margin-bottom: 10px;
    }
  
    .fc-toolbar .fc-toolbar-chunk {
      display: flex;
      justify-content: center;
      flex-wrap: wrap; /* Ensure buttons wrap on small screens */
      margin-bottom: 10px;
    }
  
    .fc-toolbar button {
      font-size: 0.75rem; /* Smaller font for mobile */
      padding: 4px 8px; /* Smaller padding for mobile */
      margin: 4px; /* Ensure some space between buttons */
    }
  
    .fc-button-group {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
  
  /* Adjust button spacing for all screen sizes */
  .fc-button-group {
    display: flex;
    justify-content: center;
    gap: 5px; /* Add space between buttons */
  }
  
  /* Modal styling for event details */
  .Modal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75); /* Dark overlay */
    z-index: 1000; /* Ensure modal appears above everything */
  }
  
  .Modal__Content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .Modal__Content h2 {
    margin-top: 0;
    font-size: 1.5rem;
  }
  
  .Modal__Content p {
    font-size: 1rem;
    margin: 10px 0;
  }
  
  .btn {
    display: inline-block;
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 0.85rem;
    cursor: pointer;
  }
  
  .btn.btn-secondary {
    background-color: #007bff;
    color: white;
    border: none;
  }
  
  .btn.btn-secondary:hover {
    background-color: #0056b3;
  }
  