/* Header.css */

/* General Styles */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: var(--color-secondary);
    color: var(--color-light);
    border-bottom: 1px solid var(--color-light);
  }
  
  .header-title {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .header-nav {
    display: flex;
    align-items: center;
    transition: max-height 0.3s ease-in-out;
  }
  
  .nav-link {
    margin: 0 1rem;
    color: var(--color-light);
    font-size: 1rem;
  }
  
  .nav-link:hover {
    color: var(--color-primary);
  }
  
  .nav-link-active {
    color: var(--color-primary);
    font-weight: bold;
  }
  
  .logout-button {
    background: none;
    border: none;
    color: var(--color-light);
    cursor: pointer;
    font-size: 1rem;
  }
  
  .logout-button:hover {
    color: var(--color-danger);
  }
  
  /* Hamburger Menu Styles */
  .hamburger {
    display: none;
    background: none;
    border: none;
    font-size: 2rem;
    color: var(--color-light);
    cursor: pointer;
  }
  
  .hamburger:focus {
    outline: none;
  }
  
  .header-nav.open {
    display: flex;
    flex-direction: column;
    max-height: 500px; /* Ensures smooth transition */
  }
  
  .header-nav.open .nav-link,
  .header-nav.open .logout-button {
    margin: 10px 0;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .header {
      flex-direction: row;
      justify-content: space-between;
    }
  
    .header-nav {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 60px;
      right: 0;
      background-color: var(--color-secondary);
      width: 100%;
      max-height: 0; /* Hidden by default */
      overflow: hidden;
      z-index: 1000;
      text-align: center;
    }
  
    .hamburger {
      display: block;
    }
  
    .header-nav.open {
      display: flex;
    }
  }
  