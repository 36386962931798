/* Reset Container */
.reset-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
  }
  
  h2 {
    margin-bottom: 20px;
    font-size: 1.8rem;
  }
  
  .reset-form .form-group {
    margin-bottom: 15px;
    text-align: left;
  }
  
  .reset-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .reset-form input {
    width: calc(100% - 20px);
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .btn {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  
  .message {
    margin-top: 20px;
    font-size: 1rem;
    color: green;
  }
  
  /* Responsive Design */
  @media (max-width: 480px) {
    .reset-container {
      padding: 10px;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    .btn {
      padding: 8px 10px;
      font-size: 0.9rem;
    }
  
    .message {
      font-size: 0.9rem;
    }
  }
  